<template>
    <section>
        <a href="https://wa.link/uqa34a" class="float" target="_blank">
            <i class="fa fa-whatsapp my-float"></i>
        </a>
    </section>
</template>

<script>
export default {
    name: 'btnWhatsapp'
}
</script>

<style lang="scss">
.float{
    position:fixed;
    width:60px;
    height:60px;
    bottom:18px;
    right:18px;
    background-color:#1BD741;
    color:#FFF;
    text-align:center;
    font-size:30px;
    box-shadow: 2px 2px 3px #595F63;
    z-index:100;
    &:hover {
        text-decoration: none;
        color: #1BD741;
        background-color:#fff;
    }
}

.my-float{
	padding:10%;
    color: #fff;
    font-size: 3rem;
    &:hover {
        color: #25d366;
    }
}
</style>